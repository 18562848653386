import React from 'react';
import {Stack} from "@mui/system";

import './styles.scss';
import LinearChart from "./chart";
import {Checkbox, IconButton, Paper, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const charts = [
  'Продажи, руб',
  'Продажи, шт',
  'Продажи, аналитика',
  'Товарный запас',
  'Цена'
];

const ItemReportCard = ({close}: {close?: () => void}) => {

  const [checkeds, setCheckeds] = React.useState(charts.map(() => true));

  const setChecked = (checked: boolean, i: number) => {
    setCheckeds(checkeds.map((value, j) => {
      if (j === i) {
        return checked;
      }
      return value;
    }));
  };

  return (
    <Paper style={{padding: '10px', position: 'relative'}} elevation={8}>
      <Typography variant="h3" gutterBottom>
        Товар такой-то
      </Typography>
      <Stack direction="row" sx={{flexWrap: 'wrap', gap: '10px'}}>
        <Paper style={{padding: '7px'}}>
          Период: 01.12.2024 - 31.12.2024
        </Paper>
        {/*<Paper style={{padding: '7px'}}>
          Название: Товар такой-то
        </Paper>*/}
        <Paper style={{padding: '7px'}}>
          SKU: 6546546
        </Paper>
        <Paper style={{padding: '7px'}}>
          Ценовая категория: строка
        </Paper>
        <Paper style={{padding: '7px'}}>
          Гендерная категория: строка
        </Paper>
        <Paper style={{padding: '7px'}}>
          Поведенческая категория: строка
        </Paper>
      </Stack>
      <Stack spacing={3} direction="row" sx={{marginTop: '10px', flexWrap: 'wrap'}}>
        {charts.map((label, i) => (
          <div key={i}>
            <Checkbox inputProps={{'aria-label': label}} defaultChecked onChange={(e) => setChecked(e.target.checked, i)}  />
            <label>{label}</label>
          </div>
        ))}
      </Stack>
      <Stack direction="row" sx={{marginTop: '10px', flexWrap: 'wrap', gap: '20px'}}>
        {charts.map((label, i) => checkeds[i] ? (
          <LinearChart key={i} label={label} type={i + 1} name="Товар такой-то" period="01.12.2024 - 31.12.2024" />
        ) : null)}
      </Stack>
      {close ? (
        <IconButton
          onClick={() => close()}
          aria-label="Закрыть"
          style={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          title="Закрыть"
        >
          <CloseIcon />
        </IconButton>

      ) : null}
    </Paper>
  );
};


export default ItemReportCard;
