import React, { useRef, useEffect, useState } from 'react';
import { max, min, scaleLinear, scaleTime, zoom, select, pointer, zoomTransform } from 'd3';
import { Typography, Checkbox, Stack, IconButton } from '@mui/material';
import { timeParse, timeFormat } from 'd3-time-format';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

type DataPoint = {
  day: string;
  selling: number;
  transfer: number;
  purchase: number;
  margin: number;
};

const data: DataPoint[] = [
  { day: '2024-12-01', selling: 100, transfer: 90, purchase: 80, margin: 20 },
  { day: '2024-12-02', selling: 120, transfer: 110, purchase: 100, margin: 20 },
  { day: '2024-12-03', selling: 130, transfer: 120, purchase: 110, margin: 20 },
  { day: '2024-12-04', selling: 140, transfer: 130, purchase: 120, margin: 20 },
  { day: '2024-12-05', selling: 150, transfer: 140, purchase: 130, margin: 20 },
  { day: '2024-12-06', selling: 160, transfer: 150, purchase: 140, margin: 20 },
  { day: '2024-12-07', selling: 170, transfer: 160, purchase: 150, margin: 20 },
  { day: '2024-12-08', selling: 180, transfer: 170, purchase: 160, margin: 20 },
  { day: '2024-12-09', selling: 190, transfer: 180, purchase: 170, margin: 20 },
  { day: '2024-12-10', selling: 200, transfer: 190, purchase: 180, margin: 20 },
];

const parseDate = timeParse('%Y-%m-%d');
const formatDate = timeFormat('%d.%m.%Y');

type PriceChartProps = {
  width: number;
  height: number;
  small?: boolean;
};

const PriceChart = ({ width, height, small }: PriceChartProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [showSelling, setShowSelling] = useState(true);
  const [showTransfer, setShowTransfer] = useState(true);
  const [showPurchase, setShowPurchase] = useState(true);
  const [showMargin, setShowMargin] = useState(true);

  const margin = { top: 20, right: 20, bottom: 50, left: 60 };
  const w = width - margin.left - margin.right;
  const h = (height - (small ? 0 : 40)) - margin.top - margin.bottom;

  const x = scaleTime().range([0, w]);
  const y = scaleLinear().range([h, 0]);

  const parsedData = data.map(d => ({ ...d, day: parseDate(d.day)! }));

  x.domain([min(parsedData, d => d.day)!, max(parsedData, d => d.day)!]);
  y.domain([0, max(parsedData, d => Math.max(d.selling, d.transfer, d.purchase, d.margin))!]);

  const drawChart = (xScale = x, yScale = y) => {
    const canvas = canvasRef.current;
    const context = canvas!.getContext('2d')!;
    context.clearRect(0, 0, canvas!.width, canvas!.height);
    context.save();
    context.translate(margin.left, margin.top);

    if (showSelling) {
      context.beginPath();
      context.moveTo(xScale(parsedData[0].day), yScale(parsedData[0].selling));
      parsedData.forEach(d => {
        context.lineTo(xScale(d.day), yScale(d.selling));
      });
      context.lineWidth = 1.5;
      context.strokeStyle = 'steelblue';
      context.stroke();
    }

    if (showTransfer) {
      context.beginPath();
      context.moveTo(xScale(parsedData[0].day), yScale(parsedData[0].transfer));
      parsedData.forEach(d => {
        context.lineTo(xScale(d.day), yScale(d.transfer));
      });
      context.lineWidth = 1.5;
      context.strokeStyle = 'green';
      context.stroke();
    }

    if (showPurchase) {
      context.beginPath();
      context.moveTo(xScale(parsedData[0].day), yScale(parsedData[0].purchase));
      parsedData.forEach(d => {
        context.lineTo(xScale(d.day), yScale(d.purchase));
      });
      context.lineWidth = 1.5;
      context.strokeStyle = 'red';
      context.stroke();
    }

    if (showMargin) {
      context.beginPath();
      context.moveTo(xScale(parsedData[0].day), yScale(parsedData[0].margin));
      parsedData.forEach(d => {
        context.lineTo(xScale(d.day), yScale(d.margin));
      });
      context.lineWidth = 1.5;
      context.strokeStyle = 'orange';
      context.stroke();
    }

    context.strokeStyle = 'black'; // Set axis color to black

    context.beginPath();
    context.moveTo(0, h);
    context.lineTo(w, h);
    context.stroke();

    context.beginPath();
    context.moveTo(0, 0);
    context.lineTo(0, h);
    context.stroke();

    const xTicks = xScale.ticks(5);
    xTicks.forEach((tick, index) => {
      if (index % 2 === 0) { // Display only every second tick
        const xPos = xScale(tick);
        context.beginPath();
        context.moveTo(xPos, h);
        context.lineTo(xPos, h + 6);
        context.stroke();
        context.fillText(formatDate(tick), xPos - 20, h + 20);
      }
    });

    const yTicks = yScale.ticks(5);
    yTicks.forEach(tick => {
      const yPos = yScale(tick);
      context.beginPath();
      context.moveTo(0, yPos);
      context.lineTo(-6, yPos);
      context.stroke();
      context.fillText(tick.toString(), -40, yPos + 3);
    });

    context.fillText('Дни', w / 2, h + 40);
    context.save();
    context.rotate(-Math.PI / 2);
    context.fillText('Рубли', -h / 2, -50);
    context.restore();

    context.restore();
  };

  const zoomBehavior = zoom()
    .scaleExtent([1, 10])
    .translateExtent([[0, 0], [w, h]])
    .extent([[0, 0], [w, h]])
    .on('zoom', (event) => {
      const transform = event.transform;
      const newX = transform.rescaleX(x);
      const newY = transform.rescaleY(y);
      drawChart(newX, newY);
    });

  useEffect(() => {
    const canvas = canvasRef.current;
    const selection = select(canvas);
    if (!small) {
      selection.call(zoomBehavior as any);
    }

    const handleMouseMove = (event: MouseEvent) => {
      const [mouseX, mouseY] = pointer(event);
      if (canvas) {
        const currentTransform = zoomTransform(canvas);
        const transformedMouseX = currentTransform.invertX(mouseX - margin.left);
        const x0 = x.invert(transformedMouseX);
        const i = parsedData.findIndex(d => d.day >= x0);
        const d = parsedData[i];
        const newX = currentTransform.rescaleX(x);
        const newY = currentTransform.rescaleY(y);
        drawChart(newX, newY);
        const context = canvas.getContext('2d')!;
        context.save();
        context.translate(margin.left, margin.top);
        if (d && !small) {
          const xPos = newX(d.day);
          if (showSelling) {
            const yPos = newY(d.selling);
            context.fillStyle = '#f6f6f6';
            context.fillRect(xPos + 10, yPos - 20, 100, 20);
            context.fillStyle = 'steelblue';
            context.fillText(`${formatDate(d.day)}: ${d.selling}`, xPos + 15, yPos - 5);
          }
          if (showTransfer) {
            const yPos = newY(d.transfer);
            context.fillStyle = '#f6f6f6';
            context.fillRect(xPos + 10, yPos - 20, 100, 20);
            context.fillStyle = 'green';
            context.fillText(`${formatDate(d.day)}: ${d.transfer}`, xPos + 15, yPos - 5);
          }
          if (showPurchase) {
            const yPos = newY(d.purchase);
            context.fillStyle = '#f6f6f6';
            context.fillRect(xPos + 10, yPos - 20, 100, 20);
            context.fillStyle = 'red';
            context.fillText(`${formatDate(d.day)}: ${d.purchase}`, xPos + 15, yPos - 5);
          }
          if (showMargin) {
            const yPos = newY(d.margin);
            context.fillStyle = '#f6f6f6';
            context.fillRect(xPos + 10, yPos - 20, 100, 20);
            context.fillStyle = 'orange';
            context.fillText(`${formatDate(d.day)}: ${d.margin}`, xPos + 15, yPos - 5);
          }
        }
        context.restore();
      }
    };

    const handleMouseOut = () => {
      if (canvas) {
        const currentTransform = zoomTransform(canvas);
        const newX = currentTransform.rescaleX(x);
        const newY = currentTransform.rescaleY(y);
        drawChart(newX, newY);
      }
    };

    selection.on('mousemove', handleMouseMove);
    selection.on('mouseout', handleMouseOut);

    if (canvas) {
      const currentTransform = zoomTransform(canvas);
      const newX = currentTransform.rescaleX(x);
      const newY = currentTransform.rescaleY(y);
      drawChart(newX, newY);
    }

    return () => {
      selection.on('.zoom', null);
      selection.on('mousemove', null);
      selection.on('mouseout', null);
    };
  }, [width, height, showSelling, showTransfer, showPurchase, showMargin, small]);

  const zoomIn = () => {
    select(canvasRef.current).call(zoomBehavior.scaleBy as any, 1.2);
  };

  const zoomOut = () => {
    select(canvasRef.current).call(zoomBehavior.scaleBy as any, 0.8);
  };

  return (
    <div>
      <Stack direction="row" spacing={2} sx={{ marginBottom: '10px' }}>
        <div>
          <Checkbox
            onClick={(e) => e.stopPropagation()}
            checked={showSelling}
            onChange={() => setShowSelling(!showSelling)}
            style={{ color: 'steelblue' }}
          />
          <Typography variant="body1" component="span">
            Цена продажи
          </Typography>
        </div>
        <div>
          <Checkbox
            onClick={(e) => e.stopPropagation()}
            checked={showTransfer}
            onChange={() => setShowTransfer(!showTransfer)}
            style={{ color: 'green' }}
          />
          <Typography variant="body1" component="span">
            Трансфертная цена
          </Typography>
        </div>
        <div>
          <Checkbox
            onClick={(e) => e.stopPropagation()}
            checked={showPurchase}
            onChange={() => setShowPurchase(!showPurchase)}
            style={{ color: 'red' }}
          />
          <Typography variant="body1" component="span">
            Закупочная цена
          </Typography>
        </div>
        <div>
          <Checkbox
            onClick={(e) => e.stopPropagation()}
            checked={showMargin}
            onChange={() => setShowMargin(!showMargin)}
            style={{ color: 'orange' }}
          />
          <Typography variant="body1" component="span">
            Маржа
          </Typography>
        </div>
        {small ? null : (
          <>
            <div>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  zoomIn();
                }}
                aria-label="Увеличить"
                title="Увеличить"
              >
                <ZoomInIcon/>
              </IconButton>
            </div>
            <div>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  zoomOut();
                }}
                aria-label="Уменьшить"
                title="Уменьшить"
              >
                <ZoomOutIcon/>
              </IconButton>
            </div>
          </>
        )}
      </Stack>
      <canvas ref={canvasRef} width={width} height={height - (small ? 5 : 45)} style={{cursor: small ? 'pointer' : 'crosshair'}}/>
    </div>
  );
};

export default PriceChart;