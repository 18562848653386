import React, { useRef, useEffect, useState } from 'react';
import { max, min, scaleLinear, scaleTime, zoom, select, pointer, zoomTransform } from 'd3';
import { timeParse, timeFormat } from 'd3-time-format';
import { Checkbox, IconButton, Stack, Typography } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

type DataPoint = {
  day: string;
  value: number;
};

const data: DataPoint[] = [
  { day: '2024-12-01', value: 100 },
  { day: '2024-12-02', value: 120 },
  { day: '2024-12-03', value: 130 },
  { day: '2024-12-04', value: 140 },
  { day: '2024-12-05', value: 150 },
  { day: '2024-12-06', value: 160 },
  { day: '2024-12-07', value: 170 },
  { day: '2024-12-08', value: 180 },
  { day: '2024-12-09', value: 190 },
  { day: '2024-12-10', value: 200 },
];

const parseDate = timeParse('%Y-%m-%d');
const formatDate = timeFormat('%d.%m.%Y');

type InventoryChartProps = {
  width: number;
  height: number;
  isMoney?: boolean;
  small?: boolean;
};

const InventoryChart = ({ width, height, isMoney, small }: InventoryChartProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [showActual, setShowActual] = useState(true);

  const margin = { top: 20, right: 20, bottom: 50, left: 60 };
  const w = width - margin.left - margin.right;
  const h = (height - (small ? 0 : 50)) - margin.top - margin.bottom;

  const x = scaleTime().range([0, w]);
  const y = scaleLinear().range([h, 0]);

  const parsedData = data.map(d => ({ ...d, day: parseDate(d.day)! }));

  x.domain([min(parsedData, d => d.day)!, max(parsedData, d => d.day)!]);
  y.domain([0, max(parsedData, d => d.value)!]);

  const drawChart = (xScale = x, yScale = y) => {
    const canvas = canvasRef.current;
    const context = canvas!.getContext('2d')!;
    context.clearRect(0, 0, canvas!.width, canvas!.height);
    context.save();
    context.translate(margin.left, margin.top);

    if (showActual) {
      context.beginPath();
      context.moveTo(xScale(parsedData[0].day), yScale(parsedData[0].value));
      parsedData.forEach(d => {
        context.lineTo(xScale(d.day), yScale(d.value));
      });
      context.lineWidth = 1.5;
      context.strokeStyle = 'steelblue';
      context.stroke();
    }

    context.strokeStyle = 'black'; // Set axis color to black

    context.beginPath();
    context.moveTo(0, h);
    context.lineTo(w, h);
    context.stroke();

    context.beginPath();
    context.moveTo(0, 0);
    context.lineTo(0, h);
    context.stroke();

    const xTicks = xScale.ticks(5);
    xTicks.forEach(tick => {
      const xPos = xScale(tick);
      context.beginPath();
      context.moveTo(xPos, h);
      context.lineTo(xPos, h + 6);
      context.stroke();
      context.fillText(formatDate(tick), xPos - 20, h + 20);
    });

    const yTicks = yScale.ticks(5);
    yTicks.forEach(tick => {
      const yPos = yScale(tick);
      context.beginPath();
      context.moveTo(0, yPos);
      context.lineTo(-6, yPos);
      context.stroke();
      context.fillText(tick.toString(), -40, yPos + 3);
    });

    context.fillText('Дни', w / 2, h + 40);
    context.save();
    context.rotate(-Math.PI / 2);
    context.fillText(isMoney ? 'Руб' : 'Шт', -h / 2, -50);
    context.restore();

    context.restore();
  };

  const zoomBehavior = zoom()
    .scaleExtent([1, 10])
    .translateExtent([[0, 0], [w, h]])
    .extent([[0, 0], [w, h]])
    .on('zoom', (event) => {
      const transform = event.transform;
      const newX = transform.rescaleX(x);
      const newY = transform.rescaleY(y);
      drawChart(newX, newY);
    });

  useEffect(() => {
    const canvas = canvasRef.current;
    const selection = select(canvas);
    if (!small) {
      selection.call(zoomBehavior as any);
    }

    const handleMouseMove = (event: MouseEvent) => {
      const [mouseX, mouseY] = pointer(event);
      if (canvas) {
        const currentTransform = zoomTransform(canvas);
        const transformedMouseX = currentTransform.invertX(mouseX - margin.left);
        const x0 = x.invert(transformedMouseX);
        const i = parsedData.findIndex(d => d.day >= x0);
        const d = parsedData[i];
        const newX = currentTransform.rescaleX(x);
        const newY = currentTransform.rescaleY(y);
        drawChart(newX, newY);
        const context = canvas.getContext('2d')!;
        context.save();
        context.translate(margin.left, margin.top);
        if (!small && showActual && d) {
          const xPos = newX(d.day);
          const yPos = newY(d.value);
          context.fillStyle = '#f6f6f6';
          context.fillRect(xPos + 10, yPos - 20, 100, 20);
          context.fillStyle = 'steelblue';
          context.fillText(`${formatDate(d.day)}: ${d.value}`, xPos + 15, yPos - 5);
        }
        context.restore();
      }
    };

    const handleMouseOut = () => {
      if (canvas) {
        const currentTransform = zoomTransform(canvas);
        const newX = currentTransform.rescaleX(x);
        const newY = currentTransform.rescaleY(y);
        drawChart(newX, newY);
      }
    };

    selection.on('mousemove', handleMouseMove);
    selection.on('mouseout', handleMouseOut);

    if (canvas) {
      const currentTransform = zoomTransform(canvas);
      const newX = currentTransform.rescaleX(x);
      const newY = currentTransform.rescaleY(y);
      drawChart(newX, newY);
    }

    return () => {
      selection.on('.zoom', null);
      selection.on('mousemove', null);
      selection.on('mouseout', null);
    };
  }, [width, height, showActual, small]);

  const zoomIn = () => {
    select(canvasRef.current).call(zoomBehavior.scaleBy as any, 1.2);
  };

  const zoomOut = () => {
    select(canvasRef.current).call(zoomBehavior.scaleBy as any, 0.8);
  };

  return (
    <div>
      {small ? (
        <Stack sx={{ marginBottom: '45px' }}/>
      ) : (
        <Stack direction="row" spacing={2} sx={{ marginBottom: '10px' }}>
          <div>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                zoomIn();
              }}
              aria-label="Увеличить"
              title="Увеличить"
            >
              <ZoomInIcon />
            </IconButton>
          </div>
          <div>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                zoomOut();
              }}
              aria-label="Уменьшить"
              title="Уменьшить"
            >
              <ZoomOutIcon />
            </IconButton>
          </div>
        </Stack>
      )}
      <canvas ref={canvasRef} width={width} height={height - (small ? 5 : 50)} style={{cursor: small ? 'pointer' : 'crosshair'}}/>
    </div>
  );
};

export default InventoryChart;