import React, {useEffect, useState} from 'react';

import './styles.scss';
import {Paper, Stack, Typography} from "@mui/material";
import DraggableResizableModal from "../draggable-resizable-modal";
import SalesChart from "../charts/sales";
import AnalyticsChart from "../charts/analytics";
import InventoryChart from "../charts/inventory";
import PriceChart from "../charts/price";

type LinearChartProps = {
  name: string;
  period: string;
  label?: string;
  type?: number;
};

const LinearChart = ({label, type, name, period}: LinearChartProps) => {
  const [openModal, setOpenModal] = useState(false);

  const [{width, height}, setSize] = useState<{width: number, height: number}>({width: 0, height: 0});

  useEffect(() => {
    setSize({width: window.innerWidth / 2 - 50, height: window.innerHeight / 2 - 50});
  }, []);

  return (
    <>
      <Paper style={{padding: '5px', cursor: 'pointer'}} onClick={() => setOpenModal(true)}>
        <Typography variant="h6">
          {label}
        </Typography>
        {type === 1 ? (
          <SalesChart width={400} height={300} isMoney small />
        ) : null}
        {type === 2 ? (
          <SalesChart width={400} height={300} small />
        ) : null}
        {type === 3 ? (
          <AnalyticsChart width={400} height={300} small />
        ) : null}
        {type === 4 ? (
          <InventoryChart width={400} height={300} small />
        ) : null}
        {type === 5 ? (
          <PriceChart width={650} height={300} small />
        ) : null}
      </Paper>
      {openModal ? (
        <DraggableResizableModal width={width} height={height} close={() => setOpenModal(false)}>
          <Stack direction="row" spacing={3}>
            <Typography variant="h6">
              {label}
            </Typography>
            <Typography variant="h6">
              {name || ''}
            </Typography>
            <Typography variant="h6">
              {period || ''}
            </Typography>
          </Stack>
          {type === 1 ? (
            <SalesChart width={width} height={height} isMoney />
          ) : null}
          {type === 2 ? (
            <SalesChart width={width} height={height} />
          ) : null}
          {type === 3 ? (
            <AnalyticsChart width={width} height={height} />
          ) : null}
          {type === 4 ? (
            <InventoryChart width={width} height={height} />
          ) : null}
          {type === 5 ? (
            <PriceChart width={width} height={height}/>
          ) : null}
          {/*<SalesChart width={width} height={height} />*/}
          {/*<LinearChartCanvas width={width} height={height}/>*/}
        </DraggableResizableModal>
      ) : null}
    </>
  );
};

export default LinearChart;
