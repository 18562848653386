import React, {ReactNode, useEffect, useRef, useState} from 'react';
import Moveable from 'react-moveable';
import {IconButton, Paper} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './styles.scss';

type DraggableResizableModalProps = {
  children: ReactNode;
  close: () => void;
  width?: number;
  height?: number;
};

const DraggableResizableModal = ({ children, width, height, close }: DraggableResizableModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [frame, setFrame] = useState({ translate: [0, 0], width: width || 395, height: height || 325 });

  useEffect(() => {
    setFrame((prev) => ({
      ...prev,
      width,
      height: (height || 0) + 20,
    }) as any);
  }, [width, height]);

  return (
    <>
      <div
        ref={modalRef}
        className="draggable-resizable-modal"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          width: `${frame.width}px`,
          height: `${frame.height}px`,
          transform: `translate(-50%, -50%)`,
          cursor: 'move',
          zIndex: 10,
        }}
      >
        <IconButton
          onClick={() => close()}
          aria-label="Закрыть"
          style={{
            position: 'absolute',
            top: '5px',
            right: '-35px',
        }}
          title="Закрыть"
        >
          <CloseIcon />
        </IconButton>
        <Paper style={{ width: '100%', height: '100%', padding: '20px' }} elevation={6}>
          {children}
        </Paper>
      </div>
      <Moveable
        hideDefaultLines
        target={modalRef}
        draggable={true}
        onDrag={({ target, beforeTranslate }) => {
          setFrame({ ...frame, translate: beforeTranslate });
          target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
        }}
        origin={false}
        // resizable={true}
        // onResize={({ target, width, height, drag }) => {
        //   const beforeTranslate = drag.beforeTranslate;
        //   setFrame({ translate: beforeTranslate, width, height });
        //   target.style.width = `${width}px`;
        //   target.style.height = `${height}px`;
        //   target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
        // }}
      />
    </>
  );
};

export default DraggableResizableModal;