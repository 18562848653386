import React, { useState } from 'react';
import {
  Button, Chip, CircularProgress, Typography
} from '@mui/material';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {useGetCategoriesTreeQuery, useGetCategoryProductsQuery} from "../../../../../redux/api/reportsApi";

import './styles.scss';

type CategoriesProps = {
  isRoot?: boolean;
  isProducts?: boolean;
  list?: any[];
  multiple?: boolean;
  selected?: any[];
  setSelected?: (item?: any[]) => void;
  categoryId?: number;
  tradePointId?: number;
};

const Categories = ({
  isRoot = false,
  list,
  setSelected = () => {},
  selected = [],
  multiple = false,
  categoryId,
  isProducts,
  tradePointId
}: CategoriesProps) => {
  const [openedId, setOpenedId] = useState<number>();

  const {data: products, isLoading} = useGetCategoryProductsQuery(
  { id: categoryId, tradePointId },
  { skip: !categoryId || !tradePointId }
);

  console.log('products', products);
  console.log('categoryId', categoryId);
  console.log('isProducts', isProducts);
  console.log('tradePointId', tradePointId);

  const items = (list || products?.body || []) as ItemType[];

  return items?.length ? (
    <ul className={`categories ${isRoot ? 'root' : ''}`}>
      {items?.map((item) => (
        <li key={item.id}>
          <Button
            color={selected?.some((i) => (categoryId && isProducts || !categoryId && !isProducts) && item.id === i.id)
              ? 'success'
              : 'inherit'}
            variant="text"
            className={isProducts && categoryId ? 'product' : ''}
            // variant={selected?.some((c) => category.id === c.id) ? 'contained' : 'text'}
            onClick={() => {
              if (!categoryId) {
                if (item.id === openedId) {
                  setOpenedId(undefined);
                } else {
                  setOpenedId(item?.id);
                }
              }
              console.log('item.children', item.children);
              if (categoryId && isProducts || !categoryId && !isProducts && !item.children) {
                if (multiple) {
                  if (selected?.some((c) => c.id === item.id)) {
                    setSelected(selected?.filter((c) => c.id !== item.id) || []);
                  } else {
                    if (!isProducts) {
                      selected = selected?.filter((c) => !(c.level < (item?.level || 0)));
                    }
                    setSelected([...(selected || []), item]);
                  }
                } else {
                  if (selected?.[0]?.id === item.id) {
                    setSelected([]);
                  } else {
                    setSelected([item]);
                  }
                }
              }
          }}>{item.name}</Button>
          {item.id === openedId ? item.children?.length ? (
            <Categories
              isProducts={isProducts}
              list={item.children}
              multiple={multiple}
              selected={selected}
              setSelected={setSelected}
              tradePointId={tradePointId}
            />
          ) : isProducts ? (
            <Categories
              categoryId={item.id}
              isProducts={isProducts}
              multiple={multiple}
              selected={selected}
              setSelected={setSelected}
              tradePointId={tradePointId}
            />
          ) : null : null}
        </li>
      ))}
      {/*products?.body?.map((product: any) => (
        <li key={product.id}>
          <Button
            color={selected?.some((c) => product.id === c.id) ? 'success' : 'inherit'}
            variant="text"
            onClick={() => {
              if (multiple) {
                if (selected?.some((c) => c.id === product.id)) {
                  setSelected(selected?.filter((c) => c.id !== product.id) || []);
                } else {
                  setSelected([...(selected || []), product]);
                }
              } else {
                if (selected?.[0]?.id === product.id) {
                  setSelected([]);
                } else {
                  setSelected([product]);
                }
              }
          }}>{product.name}</Button>
        </li>
      ))*/}
    </ul>
  ) : isLoading ? (
    <div>
      <CircularProgress color="inherit" />
    </div>
  ) : null;
}

type CategoriesTreeProps = {
  tradePointId: number;
  multiple?: boolean;
  isProducts?: boolean;
  selected?: any[];
  setSelected?: (item?: any[]) => void;
};

const CategoriesTree = ({isProducts, multiple, selected, setSelected, tradePointId}: CategoriesTreeProps) => {

  const {data: categories} = useGetCategoriesTreeQuery({});

  console.log('categories 2', categories);

  return (
    <div>
      <div className="chips-list">
        {selected?.map((c) => (
          <Chip
            label={c.name}
            onDelete={() => setSelected?.(selected?.filter((item) => c.id !== item.id))}
            variant="outlined"
          />
        ))}
      </div>
      <Typography sx={{textAlign: 'left'}}>
        <strong>{isProducts ? 'Категории и товары' : 'Категории'}</strong>
      </Typography>
      <Categories
        isProducts={isProducts}
        isRoot
        list={categories?.body as any}
        multiple={multiple}
        selected={selected}
        setSelected={setSelected}
        tradePointId={tradePointId}
      />
    </div>
  );
};


export default CategoriesTree;
