import React, { useState } from 'react';
import {Button} from '@mui/material';

import MainContainer from '../../../components/MainContainer/MainContainer';

import './styles.scss';
import ItemReportCard from "./item";
import {Stack} from "@mui/system";
import NewReportDialog from "./new";
import {PRODUCT_CARD} from "./new/constants";

const ReportCard = () => {

  const [length, setLength] = useState(2);
  const [report, setReport] = useState<ReportType>();
  const [open, setOpen] = useState(false);

  return (
    <>
      <MainContainer title='Отчёт' maxWidth={false}>
        <Stack spacing={5}>
          {Array.from({length}).map((_, i) => (
            <ItemReportCard key={i} close={i > 0 ? () => {
              setLength(length - 1);
            } : undefined} />
          ))}
        </Stack>
        <Button
          variant="contained"
          onClick={() => {
            setReport({code: PRODUCT_CARD} as ReportType);
            setLength(length + 1);
          }}
          style={{marginTop: 20}}
        >
          Добавить
        </Button>
      </MainContainer>
      {report ? (
        <NewReportDialog report={report} setReport={setReport} open={open} setOpen={setOpen} />
      ) : null}

    </>
  );
};

export default ReportCard;
